import { Link, PageProps } from "gatsby"
import styled from "@emotion/styled"
import "../components/layout"
import React from "react"
import { format } from "date-fns"
import { Helmet } from "react-helmet"

const OuterContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  // Hack: this property is currently only supported on iOS. This is necessary
  // because '-webkit-fill-available' fixes the height on iOS but breaks Chrome
  // on Windows :(
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  @media (min-width: 850px) {
    padding: 48px 96px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 850px) {
    border-top: 1px solid rgb(51, 51, 51); // Match ugly PuzzleMe border
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;

  @media (min-width: 850px) {
    margin: 0 -16px;
  }
`

const Frame = styled.iframe`
  border: none;
  flex-grow: 1;
`

const DisabledLink = styled.div`
  cursor: default;
  height: 48px;
  line-height: 48px;
  opacity: 0.5;
  text-align: center;
  width: 48px;
`

const ArrowLink = styled(Link)`
  color: inherit;
  height: 48px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  width: 48px;

  :visited {
    color: inherit;
  }
`

const PuzzleInfo = styled.span`
  padding: 8px 0;
`

const PuzzlesLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const PuzzleName = styled.span`
  font-weight: 700;
  white-space: nowrap;
`

const PuzzleDate = styled.span`
  white-space: nowrap;
`

const PuzLinkSpan = styled.span`
  display: none;

  @media (min-width: 480px) {
    display: inline;
  }
`

const PuzLink = styled.a`
  color: inherit;
  font-weight: 700;
  text-decoration: none;
`

export default function Puzzle(
  props: PageProps<
    {},
    {
      puzzle: {
        name: string
        date: string
        embedUrl: string
        thumbnailUrl: string
        puzUrl: string
      }
      previous: { slug: string }
      next: { slug: string }
    }
  >
) {
  const { puzzle, previous, next } = props.pageContext
  const formattedDate = format(new Date(puzzle.date), "MMMM do, y")
  return (
    <>
      <Helmet>
        <title>{puzzle.name} - Puzzles</title>
        <meta property="og:title" content={puzzle.name} />
        <meta property="og:image" content={puzzle.thumbnailUrl} />
        <meta property="og:description" content={formattedDate} />
      </Helmet>
      <OuterContainer>
        <InnerContainer>
          <Header>
            {previous ? (
              <ArrowLink to={`/${previous.slug}`}>&#8592;</ArrowLink>
            ) : (
              <DisabledLink>&#8592;</DisabledLink>
            )}
            <PuzzleInfo>
              <PuzzlesLink to="/puzzles">
                <PuzzleName>{puzzle.name}</PuzzleName> •{" "}
                <PuzzleDate>{formattedDate}</PuzzleDate>
              </PuzzlesLink>
              <PuzLinkSpan>
                {" "}
                [<PuzLink href={puzzle.puzUrl}>PUZ</PuzLink>]
              </PuzLinkSpan>
            </PuzzleInfo>
            {next ? (
              <ArrowLink to={`/${next.slug}`}>&#8594;</ArrowLink>
            ) : (
              <DisabledLink>&#8594;</DisabledLink>
            )}
          </Header>
          <Frame
            allowFullScreen={true}
            src={puzzle.embedUrl}
            title={puzzle.name}
          />
        </InnerContainer>
      </OuterContainer>
    </>
  )
}
